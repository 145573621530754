import React from 'react';

export const pen = 
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <title></title>
    <g id="icomoon-ignore">
    </g>
    <path d="M512 80c0-44.183-35.817-80-80-80-26.174 0-49.409 12.572-64.004 32.004l-287.996 287.996 112 112 287.996-287.996c19.432-14.595 32.004-37.83 32.004-64.004z"></path>
    <path d="M0 512l48-160 112 112z"></path>
  </svg>
;